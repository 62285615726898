<template>
  <CRow>
    <CToaster position="top-right" :autohide="1500">
      <template v-for="(toast, index) in toasts">
        <CToast
            :key="'toast' + toast.text + index"
            :show="true"
            :header="toast.header === '' ? null : toast.header"
        >
          {{ toast.text }}
        </CToast>
      </template>
    </CToaster>
    <CRow class="col-12 justify-content-center">
      <CCol lg="4" md="6" sm="12">
        <CCard>
          <CCardHeader>
            Sistem Bakım Modu
            <CSwitch
                class="float-right"
                size="sm"
                shape="pill"
                color="info"
                data-on="On"
                data-off="Off"
                :checked.sync="isInMaintenance"
            />
          </CCardHeader>
          <CCardBody>
            {{ sistemBakim }}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CCol col="12" md="6" lg="3">
      <CCard>
        <CCardHeader>
          Minimum iOS Versionu
          <CBadge color="success" class="float-right">Version {{ initialMinIosVersion }}</CBadge>
        </CCardHeader>
        <CCardBody>
          <CRow class="justify-content-center align-items-center">
            <CCol class="col-12">
              <CInput
                  label="Version"
                  v-model="minIosVersion"
                  horizontal
              >
                <template #append>
                  <CButton v-if="showMinIosVersionSaveButton && isMinIosVersionCorrectForSemver"
                           @click="changeMinIosVersion"
                           color="success">Güncelle
                  </CButton>
                </template>
              </CInput>
              <c-badge
                  class='col-12'
                  v-if='showMinIosVersionSaveButton && !isMinIosVersionCorrectForSemver'
                  color="danger"
              >Sürüm Semver'e Uygun Değil
              </c-badge>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol col="12" md="6" lg="3">
      <CCard>
        <CCardHeader>
          Minimum Android Versionu
          <CBadge color="success" class="float-right">Version {{ initialMinAndroidVersion }}</CBadge>
        </CCardHeader>
        <CCardBody>
          <CRow class="justify-content-center align-items-center">
            <CCol class="col-12">
              <CInput
                  label="Version"
                  horizontal
                  v-model="minAndroidVersion"
              >
                <template #append>
                  <CButton v-if="showMinAndroidVersionSaveButton && isMinAndroidVersionCorrectForSemver"
                           @click="changeMinAndroidVersion" color="success">Güncelle
                  </CButton>
                </template>
              </CInput>
              <c-badge
                  class='col-12'
                  v-if='showMinAndroidVersionSaveButton && !isMinAndroidVersionCorrectForSemver'
                  color="danger"
              >Sürüm Semver'e Uygun Değil
              </c-badge>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol col="12" md="6" lg="3">
      <CCard>
        <CCardHeader>
          Mevcut IOS Versionu
          <CBadge color="success" class="float-right">Version {{ initialCurrentIosVersion }}</CBadge>
        </CCardHeader>
        <CCardBody>
          <CRow class="d-flex align-items-center">
            <CCol class="col-12">
              <CInput
                  label="Version"
                  horizontal
                  v-model="currentIosVersion"
              >
                <template #append>
                  <CButton v-if="showCurrentIosVersionSaveButton && isCurrentIosVersionCorrectForSemver"
                           @click="changeCurrentIosVersion" color="success">Güncelle
                  </CButton>
                </template>
              </CInput>
              <c-badge
                  class='col-12'
                  v-if='showCurrentIosVersionSaveButton && !isCurrentIosVersionCorrectForSemver'
                  color="danger"
              >Sürüm Semver'e Uygun Değil
              </c-badge>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    <CCol col="12" md="6" lg="3">
      <CCard>
        <CCardHeader>
          Mevcut Android Versionu
          <CBadge color="success" class="float-right">Version {{ initialCurrentAndroidVersion }}</CBadge>
        </CCardHeader>
        <CCardBody>
          <CRow class="justify-content-center align-items-center">
            <CCol class="col-12">
              <CInput
                  label="Version"
                  horizontal
                  v-model="currentAndroidVersion"
              >
                <template #append>
                  <CButton v-if="showCurrentAndroidVersionSaveButton && isCurrentAndroidVersionCorrectForSemver"
                           @click="changeCurrentAndroidVersion" color="success">
                    Güncelle
                  </CButton>
                </template>
              </CInput>
              <c-badge
                  class='col-12'
                  v-if='showCurrentAndroidVersionSaveButton && !isCurrentAndroidVersionCorrectForSemver'
                  color="danger"
              >Sürüm Semver'e Uygun Değil
              </c-badge>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import {AppInfo, AppInfoAdministration} from "@/services/api-service";
import * as semver from 'semver'

export default {
  name: "Settings",
  mounted() {
    this.loadAppInfoStatus();
  },
  data: function () {
    return {
      currentIosVersionFromServer: '',
      currentAndroidVersionFromServer: '',
      minAndroidVersionFromServer: '',
      minIosVersionFromServer: '',
      initialMinAndroidVersion: '',
      initialMinIosVersion: '',
      initialCurrentAndroidVersion: '',
      initialCurrentIosVersion: '',
      minAndroidVersion: '',
      minIosVersion: '',
      currentAndroidVersion: '',
      currentIosVersion: '',
      isInMaintenance: false,
      isDataLoading: false,
      toasts: [],
      isLoading: false,
      isRollback: false,
      show: true,
      isCollapsed: true,
      sistemBakim: 'Sistemi bakım moduna almak için sistem bakım modunu sağ üst köşeden açabilirsiniz.',
      version: 'Version',
    }
  },
  watch: {
    isInMaintenance(newState) {
      if (this.isRollback) {
        this.isRollback = false;
      } else {
        this.changeMaintenanceStatus(newState);
      }
    }
  },
  computed: {
    showMinIosVersionSaveButton() {
      return this.minIosVersion !== this.minIosVersionFromServer
    },
    showMinAndroidVersionSaveButton() {
      return this.minAndroidVersion !== this.minAndroidVersionFromServer
    },
    showCurrentIosVersionSaveButton() {
      return this.currentIosVersion !== this.currentIosVersionFromServer
    },
    showCurrentAndroidVersionSaveButton() {
      return this.currentAndroidVersion !== this.currentAndroidVersionFromServer
    },
    isMinAndroidVersionCorrectForSemver() {
      return semver.valid(this.minAndroidVersion)
    },
    isCurrentAndroidVersionCorrectForSemver() {
      return semver.valid(this.currentAndroidVersion)
    },
    isMinIosVersionCorrectForSemver() {
      return semver.valid(this.minIosVersion)
    },
    isCurrentIosVersionCorrectForSemver() {
      return semver.valid(this.currentIosVersion)
    },
  },
  methods: {
    showAlert() {
      this.$swal({
        title: 'Emin misiniz?',
        text: "Yaptığınız İşlem Geri Alınamayacaktır!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#dd3333',
        confirmButtonText: 'Evet, Sil'
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal.fire(
              'Silindi!',
              'İşlem Başarıyla Gerçekleştirildi!',
              'success'
          )
        }
      });
    },
    showToast: function (text = 'Version Başarıyla Güncellendi', header = 'Bilgi') {
      this.toasts.push({text, header});
    },

    async changeMaintenanceStatus(newState) {
      this.isLoading = true;
      try {
        await AppInfoAdministration.changeAppMaintenanceStatus(newState);
        this.$toast.success("İşlem Başarılı! Sunucu Bakım Modu Başarıyla Güncellendi!")
      } catch (e) {
        this.isRollback = true;
        this.isInMaintenance = !newState;
      }
      this.isLoading = false;
    },
    async loadAppInfoStatus() {
      try {
        const {data} = await AppInfo.getAppStatus();
        this.isInMaintenance = data.inMaintenance
        this.minIosVersion = data.minimumIOSVersion
        this.minIosVersionFromServer = data.minimumIOSVersion
        this.currentIosVersion = data.currentIOSVersion
        this.currentIosVersionFromServer = data.currentIOSVersion
        this.minAndroidVersion = data.minimumAndroidVersion
        this.minAndroidVersionFromServer = data.minimumAndroidVersion
        this.currentAndroidVersion = data.currentAndroidVersion
        this.currentAndroidVersionFromServer = data.currentAndroidVersion
        this.initialMinIosVersion = data.minimumIOSVersion
        this.initialCurrentIosVersion = data.currentIOSVersion
        this.initialMinAndroidVersion = data.minimumAndroidVersion
        this.initialCurrentAndroidVersion = data.currentAndroidVersion

      } catch (e) {
        this.toasts.push("Veri Getirilemedi", "Hata");
      }

    },
    async changeMinAndroidVersion() {
      if (!this.isMinAndroidVersionCorrectForSemver) {
        this.$toast.error("Minimum android sürümü hatalı gözüküyor.")
        return
      }
      try {
        await AppInfoAdministration.changeAppMinimumAndroidVersion(this.minAndroidVersion);
        this.initialMinAndroidVersion = this.minAndroidVersion;
        this.minAndroidVersionFromServer = this.minAndroidVersion
        this.$toast.success("İşlem Başarılı! Minimum Android Versiyonu Başarıyla Güncellendi!")
      } catch (e) {
        console.log(e)
      }
    },
    async changeMinIosVersion() {
      if (!this.isMinIosVersionCorrectForSemver) {
        this.$toast.error("Minimum IOS sürümü hatalı gözüküyor.")
        return
      }
      try {
        await AppInfoAdministration.changeAppMinimumIOSVersion(this.minIosVersion);
        this.initialMinIosVersion = this.minIosVersion;
        this.minIosVersionFromServer = this.minIosVersion
        this.$toast.success("İşlem Başarılı! Minimum IOS Versiyonu Başarıyla Güncellendi!")
      } catch (e) {
        console.log(e)
      }
    },
    async changeCurrentIosVersion() {
      if (!this.isCurrentIosVersionCorrectForSemver) {
        this.$toast.error("Mevcut IOS sürümü hatalı gözüküyor.")
        return
      }
      try {
        await AppInfoAdministration.changeAppCurrentIOSVersion(this.currentIosVersion);
        this.initialCurrentIosVersion = this.currentIosVersion;
        this.currentIosVersionFromServer = this.currentIosVersion
        this.$toast.success("İşlem Başarılı! Mevcut IOS Versiyonu Başarıyla Güncellendi!")
      } catch (e) {
        console.log(e)
      }
    },
    async changeCurrentAndroidVersion() {
      if (!this.isCurrentAndroidVersionCorrectForSemver) {
        this.$toast.error("Mevcut Android sürümü hatalı gözüküyor.")
        return
      }
      try {
        await AppInfoAdministration.changeAppCurrentAndroidVersion(this.currentAndroidVersion);
        this.initialCurrentAndroidVersion = this.currentAndroidVersion
        this.currentAndroidVersionFromServer = this.currentAndroidVersion
        this.$toast.success("İşlem Başarılı! Mevcut Android Versiyonu Başarıyla Güncellendi!")
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>


